import React from "react";
import {
	AppBar,
	Toolbar,
	Avatar,
	Box,
	Typography,
	MenuItem,
	Select,
} from "@mui/material";
import { ImWhatsapp } from "react-icons/im";
import { FaSms, FaFacebookF, FaInstagram, FaPhoneAlt } from "react-icons/fa";

const ChatHeader = ({ fName, lName, phone }) => {
	const options = [
		{
			value: "SMS/MMS",
			label: "SMS/MMS",
			color: "#00a4bd",
			icon: <FaSms fontSize={30} />,
		},
		{
			value: "Whatsapp",
			label: "Whatsapp",
			color: "#25D366",
			icon: <ImWhatsapp fontSize={30} />,
		},
		{
			value: "Voice",
			label: "Voice",
			color: "gray",
			icon: <FaPhoneAlt fontSize={30} />,
		},
		{
			value: "Facebook",
			label: "Facebook",
			color: "#3b5998",
			icon: <FaFacebookF fontSize={30} />,
		},
		{
			value: "Instagram",
			label: "Instagram",
			color: "#E4405F",
			icon: <FaInstagram fontSize={30} />,
		},
	];

	const [selectedOption, setSelectedOption] = React.useState(options[0]);

	const handleChange = (event) => {
		const selected = options.find(
			(option) => option.value === event.target.value
		);
		setSelectedOption(selected);
	};

	return (
		<AppBar
			position="static"
			sx={{
				backgroundColor: selectedOption.color,
				color: "#ffffff",
				padding: "5px 5px",
				borderRadius: "unset",
				borderTop: "1px solid gray",
			}}
		>
			<Toolbar sx={{ display: "flex", alignItems: "center" }}>
				<Avatar
					sx={{
						width: 40,
						height: 40,
						marginRight: 1,
						backgroundColor: "#cccccc",
					}}
					alt="User"
				></Avatar>

				<Box sx={{ display: "flex", flexDirection: "column", marginRight: 2 }}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "bold", lineHeight: 1 }}
					>
						{`${
							fName == null
								? lName
								: lName == null
								? fName
								: fName + " " + lName + " "
						}`}
						<span style={{ fontSize: "1em" }}>(Opt-in)</span>
					</Typography>
					<Typography
						variant="body2"
						sx={{ fontSize: "0.9em", lineHeight: 1, marginRight: "10px" }}
					>
						To: {phone + " "}
						<span style={{ fontSize: "0.8em" }}>Lead</span>
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 1,
						marginLeft: "auto",
					}}
				>
					{selectedOption.icon}

					<Select
						value={selectedOption.value}
						onChange={handleChange}
						sx={{
							backgroundColor: "#ffffff",
							color: "#000000",
							fontWeight: "bold",
							height: 30,
							minWidth: 100,
							paddingBlock: "1.2rem",
						}}
					>
						{options.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default ChatHeader;
