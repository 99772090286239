import React, { useState } from "react";
import {
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
	Divider,
	Avatar,
	AppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // For the dropdown icon
import Sidebar from "../components/Sidebar";
import ChatScreen from "../components/Chat";

const HomePage = ({ flag }) => {
	// State for handling the dropdown menu
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	// State for the selected chat
	const [selectedChat, setSelectedChat] = useState(null);
	const [selectedName, setSelectedName] = useState("");
	const [selectedPhone, setSelectedPhone] = useState("");

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	// Function to handle chat selection
	const handleChatClick = (name, message, phone) => {
		setSelectedChat(message);
		setSelectedName(name);
		setSelectedPhone(phone);
	};

	// Sample data for messages
	const messages = [
		{
			name: "Sakshi Rastogi",
			message: "Chat content for User 1",
			time: "10:30 AM",
			phone: "9540338904",
		},
		{
			name: "Varun Singh",
			message: "Chat content for User 2",
			time: "Yesterday",
			phone: "9540338904",
		},
		{
			name: "Vinod Kumar",
			message: "Chat content for User 3",
			time: "2 days ago",
			phone: "9540338904",
		},
		{
			name: "Mehak Gandhi",
			message: "Chat content for User 4",
			time: "3 days ago",
			phone: "9540338904",
		},
	];

	return (
		<Box sx={{ display: "flex", height: "100vh", flexDirection: "column" }}>
			<AppBar position="static" sx={{ backgroundColor: "#1976d2" }}>
				<Toolbar
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						{/* User's name */}
						<Typography variant="h6" sx={{ color: "#fff" }}>
							GirikSMS
						</Typography>
					</Box>

					{/* Dropdown Icon */}
					<IconButton
						edge="end"
						color="inherit"
						onClick={handleMenuClick}
						aria-label="menu"
					>
						<MoreVertIcon />
					</IconButton>

					{/* Dropdown Menu */}
					<Menu
						anchorEl={anchorEl}
						open={open}
						onClose={handleMenuClose}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
					>
						<MenuItem onClick={handleMenuClose}>Profile</MenuItem>
						<MenuItem onClick={handleMenuClose}>Settings</MenuItem>
						<MenuItem onClick={handleMenuClose}>Logout</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>

			<Box sx={{ display: "flex", flexGrow: 1 }}>
				<Box sx={{ width: "20%" }}>
					<Sidebar />
				</Box>

				{/* Message List */}
				<Box
					sx={{
						width: "40%",
						backgroundColor: "#f8f9fa",
						p: 2,
						borderRight: "1px solid #ddd",
						borderLeft: "1px solid #ddd",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						variant="h5"
						gutterBottom
						sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
					>
						Messages
					</Typography>
					<List>
						{messages.map((msg, index) => (
							<ListItem
								key={index}
								alignItems="flex-start"
								sx={{ mb: 1, cursor: "pointer" }}
								onClick={() =>
									handleChatClick(msg.name, msg.message, msg.phone)
								}
							>
								<Avatar
									alt={msg.name}
									src="https://via.placeholder.com/40"
									sx={{ mr: 2 }}
								/>
								<ListItemText
									primary={msg.name} // Display user name
									secondary={msg.time} // Display timestamp
									primaryTypographyProps={{ fontWeight: "bold" }}
								/>
							</ListItem>
						))}
					</List>
				</Box>

				{/* Chat Screen */}
				<Box
					sx={{
						width: "40%",
						backgroundColor: "#f9f9f9",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{selectedChat ? (
						<ChatScreen
							chatContent={selectedChat}
							selectedName={selectedName}
							flag={flag}
							selectedPhone={selectedPhone}
						/>
					) : (
						<Typography variant="h6" color="textSecondary">
							Select a message to view chat
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default HomePage;
