import React from "react";
import {
	Box,
	Typography,
	List,
	ListItem,
	ListItemText,
	Divider,
	Button,
	Badge,
	Avatar,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Sidebar = () => {
	return (
		<Box
			sx={{
				width: "100%",
				backgroundColor: "#f3f4f6",
				// backgroundColor: "red",

				color: "#333",
				px: 0,
				py: 3,
				display: "flex",
				flexDirection: "column",
				height: "100vh",
			}}
		>
			{/* Profile Section */}
			<Box sx={{ display: "flex", alignItems: "center", mb: 3, px:2 }}>
				<Avatar sx={{ bgcolor: "#1976d2", mr: 2 }}>Y</Avatar>
				<Typography variant="subtitle1" fontWeight="bold">
					Yashraj Bapurao Abhyankar
				</Typography>
			</Box>
			<Box sx={{px:2}}>
				<Button
					variant="contained"
					color="primary"
					sx={{
						mb: 3,
						width: "100%",
						textTransform: "none",
						fontWeight: "bold",
					}}
				>
					+ New Message
				</Button>
			</Box>

			<Divider />

			{/* Categories List */}
			<List sx={{ mt: 2, px: 2 }}>
				<ListItem button>
					<ListItemText primary="ALL" />
					<Badge color="primary" badgeContent={5} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="SENT" />
					<Badge color="primary" badgeContent={3} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="SMS" />
					<Badge color="primary" badgeContent={2} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="WHATSAPP" />
					<Badge color="primary" badgeContent={2} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="WEB" />
					<Badge color="primary" badgeContent={0} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="Text-To-Voice" />
					<Badge color="primary" badgeContent={0} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="Facebook" />
					<Badge color="primary" badgeContent={2} />
				</ListItem>
				<ListItem button>
					<ListItemText primary="Pinned" />
					<Badge color="primary" badgeContent={0} />
				</ListItem>
			</List>

			<Divider sx={{ my: 2 }} />

			{/* Footer Section */}
			<Box sx={{ mt: "auto" }}>
				<List>
					<ListItem button>
						<ListItemText primary="Objects" />
						<ExpandMoreIcon />
					</ListItem>
					<ListItem button>
						<ListItemText primary="Campaign" />
						<ExpandMoreIcon />
					</ListItem>
				</List>
			</Box>
		</Box>
	);
};

export default Sidebar;
