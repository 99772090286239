import React, { useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Container,
	TextField,
	Typography,
	Link,
	FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function LoginForm() {
	// State to store form values
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		username: "",
		password: "",
		rememberMe: false,
	});

	// State to store form errors
	const [errors, setErrors] = useState({
		username: "",
		password: "",
	});

	// Handle input changes
	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	// Validate form fields
	const validateForm = () => {
		let tempErrors = { username: "", password: "" };
		let isValid = true;

		if (!formData.username) {
			tempErrors.username = "Username is required";
			isValid = false;
		} else if (!/\S+@\S+\.\S+/.test(formData.username)) {
			tempErrors.username = "Username must be a valid email";
			isValid = false;
		}

		if (!formData.password) {
			tempErrors.password = "Password is required";
			isValid = false;
		} else if (formData.password.length < 6) {
			tempErrors.password = "Password must be at least 6 characters";
			isValid = false;
		}

		setErrors(tempErrors);
		return isValid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			navigate("/home");
		}
	};

	return (
		<Container maxWidth="xs">
			<Box
				sx={{
					mt: 8,
					p: 3,
					borderRadius: 1,
					boxShadow: 3,
					textAlign: "center",
					bgcolor: "white",
				}}
			>
				<TextField
					label="Username"
					name="username"
					fullWidth
					variant="outlined"
					margin="normal"
					type="email"
					value={formData.username}
					onChange={handleChange}
					error={!!errors.username}
					helperText={errors.username}
				/>

				{/* Password Input */}
				<TextField
					label="Password"
					name="password"
					fullWidth
					variant="outlined"
					margin="normal"
					type="password"
					value={formData.password}
					onChange={handleChange}
					error={!!errors.password}
					helperText={errors.password}
				/>

				{/* Log In Button */}
				<Button
					variant="contained"
					color="primary"
					fullWidth
					sx={{ mt: 2, mb: 2 }}
					onClick={handleSubmit}
				>
					Log In
				</Button>

				<FormControlLabel
					control={
						<Checkbox
							name="rememberMe"
							color="primary"
							checked={formData.rememberMe}
							onChange={handleChange}
						/>
					}
					label="Remember me"
					sx={{ display: "flex", justifyContent: "start" }}
				/>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Link href="#" variant="body2" color="primary">
						Forgot Your Password?
					</Link>
					<Link href="#" variant="body2" color="primary">
						Signup
					</Link>
				</Box>
			</Box>
		</Container>
	);
}

export default LoginForm;
