import urls from "../urlConfig/urlConfig";
import axios from "axios";

class TemplateService {
	// Static method to get templates
	static async getTemplates() {
		try {
			const response = await axios.get(`${urls.base_url}/get-template`);
			if (response.status === 200) {
				return response?.data?.response?.contents;
			} else {
				console.error("Failed to fetch templates:", response.statusText);
				return null;
			}
		} catch (error) {
			console.error("Error fetching templates:", error);
			return null;
		}
	}

	static async createTemplate(message, templateName) {
		try {
			const templateData = {
				message: message,
				templateName: templateName,
			};

			const response = await axios.post(
				`${urls.base_url}/create-template`,
				templateData
			);

			if (response.status === 200) {
				console.log("Template created successfully");
				return response.data;
			} else {
				console.error("Failed to create template:", response.statusText);
				return null;
			}
		} catch (error) {
			console.error("Error creating template:", error);
			return null;
		}
	}
}

export default TemplateService;
