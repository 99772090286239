import React, { useState, useRef, useEffect } from "react";
import {
	Box,
	Typography,
	Paper,
	Container,
	styled,
	AppBar,
} from "@mui/material";
import { BsCheck2All } from "react-icons/bs";

import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import ChatService from "../services/chat.service";
import io from "socket.io-client";
import urls from "../urlConfig/urlConfig";

const socket = io(urls.app_domain);

const StyledMessageContainer = styled(Box)(({ theme }) => ({
	height: "calc(100vh - 320px)",
	overflowY: "auto",
	backgroundColor: "#fff",
	padding: "1rem 1rem 0 1rem",
}));

const MessageBubble = styled(Paper)(({ theme, isOwn }) => ({
	padding: theme.spacing(1.5),
	// maxWidth: "75%",
	marginBottom: theme.spacing(1),
	borderRadius: isOwn ? "12px 0 12px 12px" : "0 12px 12px 12px",
	backgroundColor: isOwn ? "#DCF8C6" : "#ddd",
	alignSelf: isOwn ? "flex-end" : "flex-start",
	position: "relative",
	boxShadow: "none",
}));

const ChatScreen = ({
	fName,
	lName,
	phone,
	recordId,
	selectedName,
	flag,
	selectedPhone,
}) => {
	const [messages, setMessages] = useState([
		{
			id: 1,
			text: "Hey there! How are you?",
			sender: "John",
			timestamp: "10:30 AM",
			isOwn: false,
			status: "read",
			avatar: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde",
		},
		{
			id: 2,
			text: "I'm good, thanks! Just enjoying the day. What about you?",
			sender: "You",
			timestamp: "10:31 AM",
			isOwn: true,
			status: "read",
			avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
		},
		{
			id: 3,
			text: "Just finished a meeting. Glad to have some free time now.",
			sender: "John",
			timestamp: "10:32 AM",
			isOwn: false,
			status: "read",
			avatar: "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde",
		},
		{
			id: 4,
			text: "Nice! Let's catch up soon.",
			sender: "You",
			timestamp: "10:33 AM",
			isOwn: true,
			status: "read",
			avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
		},
	]);

	const [newMessage, setNewMessage] = useState("");
	const [showEmoji, setShowEmoji] = useState(false);
	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	useEffect(() => {
		socket.on("smsMessage", (data) => {
			console.log("data", data.senderNumber);
			console.log("phone Number", phone);
			if(data.senderNumber === phone){
				const msg = {
					id: messages.length + 1,
					text: data.incomingMsg,
					sender: "john",
					timestamp: new Date().toLocaleTimeString([], {
						hour: "2-digit",
						minute: "2-digit",
					}),
					isOwn: false,
					status: "read",
					avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
				};
				setMessages((prevMessages) => [...prevMessages, msg]);
			}
			
		});

		return () => {
			socket.off("smsMessage");
		};
	}, []);

	const handleSendMessage = async (file) => {
		if (newMessage.trim()) {
			const message = {
				id: messages.length + 1,
				text: newMessage,
				sender: "You",
				timestamp: new Date().toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				}),
				isOwn: true,
				status: "sent",
				avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
			};
			const formData = new FormData();
			if (file != undefined) {
				formData.append("file", file);
			}
			formData.append("message", newMessage);
			formData.append("to", phone);
			formData.append("recordId", recordId);
			const response = await ChatService.sendMessage(formData);
			setMessages([...messages, message]);
			setNewMessage("");
			setShowEmoji(false);
		}
	};

	const onEmojiClick = (emojiObject) => {
		setNewMessage(newMessage + emojiObject.emoji);
	};

	return (
		<Container
			sx={{
				width: "100%",
				maxWidth: "none",
				height: "100vh",
				pt: 0,
				paddingX: 0,
				padding: "unset !important",
			}}
		>
			<Paper
				elevation={3}
				sx={{
					height: "100%",
					borderRadius: 2,
					overflow: "auto",
					borderRadius: "unset !important",
				}}
			>
				<AppBar
					position="static"
					color="default"
					elevation={1}
					sx={{ borderBottom: "1px solid #e0e0e0" }}
				>
					<ChatHeader
						fName={flag === "default" ? selectedName : fName + lName}
						phone={flag === "default" ? selectedPhone : phone}
					/>
				</AppBar>

				<StyledMessageContainer>
					{messages.map((message) => (
						<Box
							key={message.id}
							sx={{
								display: "flex",
								flexDirection: message.isOwn ? "row-reverse" : "row",
								alignItems: "flex-start",
								mb: 2,
							}}
						>
							<MessageBubble isOwn={message.isOwn}>
								<Typography variant="body1">{message.text}</Typography>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "flex-end",
										mt: 0.5,
									}}
								>
									<Typography variant="caption" color="textSecondary">
										{message.timestamp}
									</Typography>
									{message.isOwn && (
										<BsCheck2All
											style={{
												marginLeft: 4,
												color:
													message.status === "read" ? "#34B7F1" : "#A0A0A0",
											}}
										/>
									)}
								</Box>
							</MessageBubble>
						</Box>
					))}
					<div ref={messagesEndRef} />
				</StyledMessageContainer>

				<Box
					sx={{ p: 2, borderTop: "1px solid #e0e0e0", position: "relative" }}
				>
					<ChatFooter
						newMessage={newMessage}
						setNewMessage={setNewMessage}
						handleSendMessage={handleSendMessage}
						onEmojiClick={onEmojiClick}
						showEmoji={showEmoji}
						setShowEmoji={setShowEmoji}
					/>
				</Box>
			</Paper>
		</Container>
	);
};

export default ChatScreen;
