import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import HomePage from "./pages/Home";
import ChatScreen from "./components/Chat";

function App() {
	const urlParams = new URLSearchParams(window.location.search);
	const flag = urlParams.get("flag");
	console.log(flag, "............falg");
	// const flag = "default";
	const recordId = urlParams.get("recordId");
	const fName = urlParams.get("firstName");
	const lName = urlParams.get("lastName");
	const phone = urlParams.get("phone");
  

	console.log(flag, "......................flag");
	let phoneNumber = phone?.includes("+") ? phone : `+${phone?.trim()}`;
	const router = createBrowserRouter([
		{
			path: "/",
			element: <LoginForm />,
		},
		{
			path: "/chat",
			element: (
				<ChatScreen
					fName={fName}
					lName={lName}
					recordId={recordId}
					selectedName={null}
					flag={flag}
          phone={phoneNumber}
				/>
			),
		},
		{
			path: "/home",
			element: <HomePage flag={"default"} />,
		},
	]);

	return (
		<div className="App">
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
