import React, { useState } from "react";
import {
	Box,
	TextField,
	IconButton,
	InputAdornment,
	Typography,
} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import { BsEmojiSmile, BsPaperclip, BsImage, BsSend } from "react-icons/bs";
import { GoProjectTemplate } from "react-icons/go";
import TemplateModal from "./TemplateModal";

const ChatFooter = ({
	newMessage,
	setNewMessage,
	handleSendMessage,
	onEmojiClick,
	showEmoji,
	setShowEmoji,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null); // State to manage selected file

	const handleKeyPress = (event) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			handleSendMessage();
		}
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setSelectedFile(file);
		}
	};

	const handleSendWithAttachment = () => {
		if (selectedFile) {
			handleSendMessage(selectedFile);
			// setSelectedFile(null);
		} else {
			handleSendMessage();
		}
	};

	const maxCharacterLimit = 1550 - newMessage.length;
	const remainingCharacters = newMessage.length;

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<Box
			sx={{
				display: "flex",
				gap: 1,
			}}
			flexDirection={"column"}
		>
			<Box
				sx={{ display: "flex", gap: 1, alignItems: "center" }}
				justifyContent={"space-between"}
			>
				<TextField
					select
					size="small"
					defaultValue="Phone"
					sx={{ width: 100 }}
					SelectProps={{
						native: true,
					}}
				>
					<option value="Phone">Phone</option>
					<option value="Email">Mobile Phone</option>
				</TextField>
				<TextField
					select
					size="small"
					defaultValue="GirikSMS"
					sx={{ width: 200 }}
					SelectProps={{
						native: true,
					}}
				>
					<option value="GirikSMS">GirikSMS (+13156784315)</option>
				</TextField>
			</Box>

			<Box>
				{showEmoji && (
					<Box sx={{ position: "absolute", bottom: "100%", right: 16 }}>
						<EmojiPicker onEmojiClick={onEmojiClick} />
					</Box>
				)}
				<TextField
					fullWidth
					multiline
					maxRows={4}
					placeholder="Say something..."
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
					onKeyPress={handleKeyPress}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									color="primary"
									sx={{
										backgroundColor: "#3b5998",
										color: "#fff",
										":hover": { backgroundColor: "#3b5998" },
									}}
									onClick={handleSendWithAttachment}
								>
									<BsSend />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Box>

			<Box
				sx={{ display: "flex", alignItems: "center", gap: 1 }}
				justifyContent={"space-between"}
			>
				<Box>
					<Typography>
						{remainingCharacters}/{maxCharacterLimit}
					</Typography>
				</Box>
				<Box>
					<IconButton onClick={() => setShowEmoji(!showEmoji)}>
						<BsEmojiSmile />
					</IconButton>
					<IconButton component="label">
						<BsPaperclip />
						<input type="file" hidden onChange={handleFileChange} />
					</IconButton>
					<IconButton onClick={handleOpenModal}>
						<GoProjectTemplate />
					</IconButton>
					<IconButton>
						<BsImage />
					</IconButton>
				</Box>
			</Box>

			{selectedFile && (
				<Box mt={1}>
					<Typography variant="body2" color="textSecondary">
						Attached file: {selectedFile.name}
					</Typography>
				</Box>
			)}

			<TemplateModal open={openModal} onClose={handleCloseModal} />
		</Box>
	);
};

export default ChatFooter;
