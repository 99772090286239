import React, { useEffect, useState } from "react";
import {
	Box,
	Dialog,
	DialogContent,
	Tabs,
	Tab,
	Checkbox,
	FormControlLabel,
	TextField,
	Button,
	Typography,
	Divider,
} from "@mui/material";
import templateService from "../services/template.service";
import { FaRegCheckCircle } from "react-icons/fa";

const TemplateModal = ({ open, onClose }) => {
	const [activeTab, setActiveTab] = useState(0);
	const [templateName, setTemplateName] = useState("");
	const [message, setMessage] = useState("");
	const [templates, setTemplates] = useState([]);
	const [success, setSuccess] = useState(false);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const response = await templateService.createTemplate(
			message,
			templateName
		);

		if (response?.success) {
			getTemplates();
			setSuccess(true);
			setTemplateName("");
			setMessage("");
		} else {
			console.error("Failed to create template");
		}
	};

	const getTemplates = async () => {
		const response = await templateService.getTemplates();
		setTemplates(response);
	};

	useEffect(() => {
		getTemplates();
	}, []);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogContent>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						backgroundColor: "#f0f0f0",
						borderRadius: 2,
						mt: 2,
						mb: 3,
						p: 1,
					}}
				>
					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						centered
						TabIndicatorProps={{ style: { display: "none" } }}
						sx={{
							display: "flex",
							gap: 1,
							width: "100%",
							"& .MuiTab-root": {
								flex: 1,
								borderRadius: 2,
								padding: "8px 16px",
								fontWeight: "bold",
								fontSize: "1rem",
								textTransform: "none",
								"&:hover": { backgroundColor: "#e0e0e0", color: "#0d0d0d" },
							},
							"& .Mui-selected": {
								backgroundColor: "#1976d2",
								color: "#fff ",
							},
						}}
					>
						<Tab label="List" />
						<Tab label="Form" />
					</Tabs>
				</Box>
				<Box mt={1}>
					{activeTab === 0 ? (
						<Box p={2}>
							<Typography variant="subtitle1" gutterBottom>
								Select a Template:
							</Typography>
							<Divider sx={{ mb: 2 }} />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: 1.5,
									pl: 2,
								}}
							>
								{templates?.length > 0
									? templates.map((template) => {
											return (
												<FormControlLabel
													control={<Checkbox />}
													label={template.friendly_name}
												/>
											);
									  })
									: <Typography>There is no template available</Typography>}
							</Box>
						</Box>
					) : success ? (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: "2rem",
								flexDirection: "column",
								bgcolor: "#f5f5f5",
								borderRadius: "8px",
								boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
								maxWidth: "400px",
								margin: "auto",
							}}
						>
							<FaRegCheckCircle fontSize={80} color="#22bb33" />
							<Box sx={{ marginTop: "1rem", textAlign: "center" }}>
								<Typography
									variant="h5"
									fontWeight="bold"
									color="#333"
									gutterBottom
								>
									Success!
								</Typography>
								<Typography variant="body1" color="textSecondary">
									Your template was completed successfully.
								</Typography>
							</Box>
							<Box sx={{ display: "flex", gap: "1rem", marginTop: "1.5rem" }}>
								<Button
									variant="contained"
									color="primary"
									sx={{
										padding: "0.5rem 1.5rem",
										borderRadius: "20px",
										fontWeight: "bold",
										textTransform: "none",
										bgcolor: "#007BFF",
										"&:hover": { bgcolor: "#0056b3" },
									}}
									onClick={() => {
										setActiveTab(0);
										setSuccess(false);
									}}
								>
									Go to List
								</Button>
								<Button
									variant="outlined"
									sx={{
										padding: "0.5rem 1.5rem",
										borderRadius: "20px",
										fontWeight: "bold",
										textTransform: "none",
										color: "#333",
										borderColor: "#bbb",
										"&:hover": { borderColor: "#888", bgcolor: "#f0f0f0" },
									}}
									onClick={() => {
										onClose();
										setSuccess(false);
									}}
								>
									Close
								</Button>
							</Box>
						</Box>
					) : (
						<form onSubmit={handleFormSubmit}>
							<Box p={2}>
								<Typography variant="subtitle1" gutterBottom>
									Create a New Template:
								</Typography>
								<Divider sx={{ mb: 2 }} />
								<TextField
									label="Template Name"
									fullWidth
									margin="normal"
									value={templateName}
									onChange={(e) => setTemplateName(e.target.value)}
								/>
								<TextField
									label="Message"
									fullWidth
									multiline
									rows={4}
									margin="normal"
									value={message}
									onChange={(e) => setMessage(e.target.value)}
								/>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									fullWidth
									sx={{ mt: 2, py: 1 }}
								>
									Submit
								</Button>
							</Box>
						</form>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default TemplateModal;
