import urls from "../urlConfig/urlConfig";
import axios from "axios";

class ChatService {
	static async sendMessage(formData) {
		try {
			const response = await axios.post(`${urls.base_url}/send-sms`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});

			if (response.ok) {
				console.log("Message sent successfully!");
				return response;
			} else {
				console.error("Failed to send message", response.status);
			}
		} catch (error) {
			console.error("Error sending message:", error);
		}
	}
}

export default ChatService;
